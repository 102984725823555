/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/lato-v16-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Lato Regular"), local("Lato-Regular"),
    url("./assets/fonts/lato-v16-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/lato-v16-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/lato-v16-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/lato-v16-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/lato-v16-latin-regular.svg#Lato") format("svg"); /* Legacy iOS */
}

/* lato-italic - latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/lato-v16-latin-italic.eot"); /* IE9 Compat Modes */
  src: local("Lato Italic"), local("Lato-Italic"),
    url("./assets/fonts/lato-v16-latin-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/lato-v16-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/lato-v16-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/lato-v16-latin-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/lato-v16-latin-italic.svg#Lato") format("svg"); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/lato-v16-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Lato Bold"), local("Lato-Bold"),
    url("./assets/fonts/lato-v16-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/lato-v16-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/lato-v16-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/lato-v16-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/lato-v16-latin-700.svg#Lato") format("svg"); /* Legacy iOS */
}

/* lato-700italic - latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/lato-v16-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"),
    url("./assets/fonts/lato-v16-latin-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/lato-v16-latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./assets/fonts/lato-v16-latin-700italic.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/lato-v16-latin-700italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./assets/fonts/lato-v16-latin-700italic.svg#Lato") format("svg"); /* Legacy iOS */
}

/* This prevents the post-refresh from creating an unwanted extra scrollbar on the screen on every API call */
/* If we need other iframes in the future we should tackle this issue differently */
iframe {
  display: none;
}

html,
body,
#root {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  position: relative;
  height: 100%;
  width: 100%;
  cursor: default;
}

* {
  box-sizing: border-box;
}

div {
  scrollbar-width: thin;
}

h1 {
  font-family: "Lato", sans-serif;
  font-size: 1.875em;
  color: #253048;
}

p {
  text-align: left;
  font-family: "Lato", sans-serif;
  font-size: 1em;
  line-height: 1.188em;
  color: #35384d;
}

span {
  font-family: "Lato", sans-serif;
}

a {
  cursor: pointer;
  text-decoration: none;
}

button {
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

input {
  font: inherit;
  cursor: auto;
}

.positive {
  background: #7fc384;
}

.negative {
  background: #dd4242;
}

.neutral {
  background: #3068c1;
}

.notExplicit {
  background: #fad39c;
}

.flex {
  display: flex;
}

/* Animation */

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

.fade-in-up {
  animation: fadeInUp ease 1s;
  -webkit-animation: fadeInUp ease 1s;
  -moz-animation: fadeInUp ease 1s;
  -o-animation: fadeInUp ease 1s;
  -ms-animation: fadeInUp ease 1s;
}

.fade-in-left {
  animation: fadeInLeft ease 1s;
  -webkit-animation: fadeInLeft ease 1s;
  -moz-animation: fadeInLeft ease 1s;
  -o-animation: fadeInLeft ease 1s;
  -ms-animation: fadeInLeft ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.fade-out {
  animation: fadeOut ease 1s forwards;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.pulse {
  animation: pulse 1.2s ease-in-out infinite;
  -webkit-animation: pulse 1.2s ease-in-out infinite;
  -moz-animation: pulse 1.2s ease-in-out infinite;
  -o-animation: pulse 1.2s ease-in-out infinite;
  -ms-animation: pulse 1.2s ease-in-out infinite;
}

.opaque.show {
  opacity: 1 !important;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* scrollbar */
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  min-height: 40px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  background-color: #ccc;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* CSS3 Media Queries */
.main-content {
  display: none;
}

@media (max-width: 1079px) {
  .ReactModalPortal {
    display: none;
  }
}

@media (min-width: 1080px) {
  .notSupported {
    display: none;
  }

  .main-content {
    display: block;
    width: 920px;
  }

  .main-content .filters > .dropdown-label {
    display: block;
  }

  .ReactModalPortal {
    display: block;
  }
}

@media (min-width: 1366px) {
  .main-content {
    width: 1175px;
  }
}

@media (min-width: 1920px) {
  .main-content .top-section-right .section-left .dropdown-label {
    display: flex;
    flex-direction: row;
  }
}
